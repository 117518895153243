<template>
  <v-sheet 
    @click="$router.push(`/games/${game.id}`)" 
    style="position: relative; cursor: pointer;" 
    elevation="3"
  >
    <v-sheet class="d-flex justify-space-around px-4 pt-5">
      <v-img
        :src="game.home_club_logo_url"
        height="50"
        width="50"
        contain
      ></v-img>
      <v-img
        :src="game.away_club_logo_url"
        height="50"
        width="50"
        contain
      ></v-img>
    </v-sheet>
    <v-sheet class="d-flex justify-space-around px-4"> 
      <v-sheet class="text-button">{{game.home_club_short_name}}</v-sheet>
      <v-sheet class="text-button">{{game.away_club_short_name}}</v-sheet>
    </v-sheet>
    <div style="text-align: center; margin-top: -58px;">
      <v-sheet style="height: 30px;">
        <v-sheet 
          class="text-button" 
          v-if="game.score_home != 0 && game.score_away != 0"
        >
          {{game.score_home}} - {{game.score_away}}
        </v-sheet>
      </v-sheet>
    </div>
    <v-sheet class="mt-6 pb-2 d-flex justify-center">
      <v-chip label outlined small>
        {{prettyDate}}
      </v-chip>
    </v-sheet>
    <v-sheet class="d-flex mt-1 mx-auto" style="width: 60%;">
      <v-menu 
        open-on-hover
        offset-y
        v-for="state in season.states || []"
        :key="state.id"
        center
      >
        <template v-slot:activator="{on, attrs}">
          <v-sheet
            v-bind="attrs"
            :style="`background-color: ${ state_done(state.id) ? '#4dff52' : 'lightgrey' }`"
            v-on="on"
            class="ma-1"
            style="height: 5px; border-radius: 5px; flex-grow: 1; background-color: grey;"
          ></v-sheet>
        </template>
        <v-card>
          <v-card-text>
            {{ state.state_name }}
          </v-card-text>
        </v-card>
        <!-- {{ state }} -->
      </v-menu>
    </v-sheet>
    <v-menu open-on-hover offset-y v-if="game.game_info">
      <template v-slot:activator="{on, attrs}">
        <v-btn
          :style="`position: absolute; right: 5px; top: ${ game.game_error ? '40' : '5' }px;`"
          v-bind="attrs"
          v-on="on"
          fab
          x-small
          text
        >
          <v-icon color="blue" size="35">
            mdi-information-slab-symbol
          </v-icon>
        </v-btn>
      </template>
      <v-card max-width="200">
        <v-card-text>
          {{ game.game_info }}
        </v-card-text>
      </v-card>
    </v-menu>
    <v-menu open-on-hover offset-y v-if="game.game_error">
      <template v-slot:activator="{on, attrs}">
        <v-btn
          style="position: absolute; right: 5px; top: 5px;"
          v-bind="attrs"
          v-on="on"
          fab
          x-small
          text
        >
          <v-icon color="red">
            mdi-alert-outline
          </v-icon>
        </v-btn>
      </template>
      <v-card max-width="200">
        <v-card-text>
          {{ game.game_error }}
        </v-card-text>
      </v-card>
    </v-menu>
    <!-- {{game}} -->
  </v-sheet>
</template>


<script>
export default {
  props: ['game', 'season'],
  computed: {
    prettyDate() {
      const date = new Date(this.game.starttime_unix)
      return date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear()
    }
  },
  methods: {
    state_done(state_id) {
      const state = this.season.states.find(s => s.id == state_id)
      const done_state = this.season.states.find(s => s.id == this.game.done_analysis_state)

      if(!done_state) return false

      if(this.game.done_analysis_state == state_id) return true
      if(state.state_order < done_state.state_order) return true
      return false
    }
  }
}
</script>