var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"elevation":"3"},on:{"click":function($event){return _vm.$router.push(("/games/" + (_vm.game.id)))}}},[_c('v-sheet',{staticClass:"d-flex justify-space-around px-4 pt-5"},[_c('v-img',{attrs:{"src":_vm.game.home_club_logo_url,"height":"50","width":"50","contain":""}}),_c('v-img',{attrs:{"src":_vm.game.away_club_logo_url,"height":"50","width":"50","contain":""}})],1),_c('v-sheet',{staticClass:"d-flex justify-space-around px-4"},[_c('v-sheet',{staticClass:"text-button"},[_vm._v(_vm._s(_vm.game.home_club_short_name))]),_c('v-sheet',{staticClass:"text-button"},[_vm._v(_vm._s(_vm.game.away_club_short_name))])],1),_c('div',{staticStyle:{"text-align":"center","margin-top":"-58px"}},[_c('v-sheet',{staticStyle:{"height":"30px"}},[(_vm.game.score_home != 0 && _vm.game.score_away != 0)?_c('v-sheet',{staticClass:"text-button"},[_vm._v(" "+_vm._s(_vm.game.score_home)+" - "+_vm._s(_vm.game.score_away)+" ")]):_vm._e()],1)],1),_c('v-sheet',{staticClass:"mt-6 pb-2 d-flex justify-center"},[_c('v-chip',{attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.prettyDate)+" ")])],1),_c('v-sheet',{staticClass:"d-flex mt-1 mx-auto",staticStyle:{"width":"60%"}},_vm._l((_vm.season.states || []),function(state){return _c('v-menu',{key:state.id,attrs:{"open-on-hover":"","offset-y":"","center":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"ma-1",staticStyle:{"height":"5px","border-radius":"5px","flex-grow":"1","background-color":"grey"},style:(("background-color: " + (_vm.state_done(state.id) ? '#4dff52' : 'lightgrey')))},'v-sheet',attrs,false),on))]}}],null,true)},[_c('v-card',[_c('v-card-text',[_vm._v(" "+_vm._s(state.state_name)+" ")])],1)],1)}),1),(_vm.game.game_info)?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:(("position: absolute; right: 5px; top: " + (_vm.game.game_error ? '40' : '5') + "px;")),attrs:{"fab":"","x-small":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue","size":"35"}},[_vm._v(" mdi-information-slab-symbol ")])],1)]}}],null,false,1286366334)},[_c('v-card',{attrs:{"max-width":"200"}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.game.game_info)+" ")])],1)],1):_vm._e(),(_vm.game.game_error)?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"5px","top":"5px"},attrs:{"fab":"","x-small":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-alert-outline ")])],1)]}}],null,false,3629727005)},[_c('v-card',{attrs:{"max-width":"200"}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.game.game_error)+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }